.top-menu {
  background: #fff;
  position: fixed;
  width: 100%;
  height: 1.493333rem;
  top: 0;
  z-index: 9;
  -webkit-transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  -moz-transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}

.top-menu.transparent {
  background: transparent;
}

.top-menu.show {
  height: 100vh;
}

.top-menu.show .top-menu-header {
  border-bottom: 0.026667rem solid #eeeeee;
}

.top-menu .top-menu-header {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0.373333rem 0.48rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.top-menu .top-menu-header .logo-box {
  width: 1.84rem;
  height: 0.746667rem;
}

.top-menu .top-menu-header .logo-box img {
  width: 100%;
  height: 100%;
}

.top-menu .top-menu-header .logo-box-66 {
  width: 3.778rem;
  height: 0.746667rem;
}

.top-menu .top-menu-header .logo-box-66 img {
  width: 100%;
  height: 100%;
}

.top-menu .top-menu-header .menu-open-app {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}

.top-menu .top-menu-header .menu-open-app .open-app {
  width: 1.813333rem;
  height: 0.746667rem;
  background: #FFFFFF;
  -webkit-border-radius: 0.373333rem;
  border-radius: 0.373333rem;
  border: 0.013333rem solid #F5333F;
  font-size: 0.32rem;
  font-weight: 400;
  color: #F5333F;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: 1.12rem;
}

.top-menu .top-menu-header .menu-open-app .collapse-button {
  display: inline-block;
  vertical-align: middle;
  background: transparent;
  border: none;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  position: absolute;
  right: 0;
}

.top-menu .top-menu-header .menu-open-app .collapse-button > span {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-weight: 400;
  vertical-align: baseline;
  background: transparent;
  display: block;
  padding-top: 0.06rem;
  padding-bottom: 0.06rem;
  -webkit-transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  -moz-transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}

.top-menu .top-menu-header .menu-open-app .collapse-button > span::before {
  content: " ";
  display: block;
  height: 0.04rem;
  width: 0.373333rem;
  background-color: #1a1a1a;
  -webkit-border-top-right-radius: 0.026667rem;
  border-top-right-radius: 0.026667rem;
  -webkit-border-bottom-left-radius: 0.026667rem;
  border-bottom-left-radius: 0.026667rem;
  -webkit-transition: background-color 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0s, -webkit-transform 0.3s cubic-bezier(0.23, 1, 0.32, 1) 0.15s;
  transition: background-color 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0s, -webkit-transform 0.3s cubic-bezier(0.23, 1, 0.32, 1) 0.15s;
  -moz-transition: transform 0.3s cubic-bezier(0.23, 1, 0.32, 1) 0.15s, background-color 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0s, -moz-transform 0.3s cubic-bezier(0.23, 1, 0.32, 1) 0.15s;
  transition: transform 0.3s cubic-bezier(0.23, 1, 0.32, 1) 0.15s, background-color 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0s;
  transition: transform 0.3s cubic-bezier(0.23, 1, 0.32, 1) 0.15s, background-color 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0s, -webkit-transform 0.3s cubic-bezier(0.23, 1, 0.32, 1) 0.15s, -moz-transform 0.3s cubic-bezier(0.23, 1, 0.32, 1) 0.15s;
}

.top-menu .top-menu-header .menu-open-app .collapse-button.show > .line-top {
  -webkit-transform: translateY(0.16rem);
  -moz-transform: translateY(0.16rem);
  transform: translateY(0.16rem);
}

.top-menu .top-menu-header .menu-open-app .collapse-button.show > .line-top::before {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg);
}

.top-menu .top-menu-header .menu-open-app .collapse-button.show > .line-middle {
  opacity: 0;
}

.top-menu .top-menu-header .menu-open-app .collapse-button.show > .line-bottom {
  -webkit-transform: translateY(-0.16rem);
  -moz-transform: translateY(-0.16rem);
  transform: translateY(-0.16rem);
}

.top-menu .top-menu-header .menu-open-app .collapse-button.show > .line-bottom::before {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.top-menu .nav-list {
  position: absolute;
  top: 1.266667rem;
  bottom: 0;
  width: 100%;
  opacity: 0;
  -webkit-transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  -moz-transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  visibility: hidden;
  overflow-x: hidden;
  z-index: 100;
  -webkit-overflow-scrolling: touch;
  background-color: #fff;
  max-height: 100vh;
  overflow-y: auto;
}

.top-menu .nav-list.show {
  opacity: 1;
  visibility: visible;
  z-index: 200;
}

.top-menu .nav-list .list-box {
  padding: 0.426667rem 0.48rem;
}

.top-menu .nav-list .list-box .list-item {
  height: 1.493333rem;
  font-size: 0.506667rem;
  font-weight: 600;
  color: #333333;
  line-height: 0.533333rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 0.013333rem solid #eeeeee;
}

.top-menu .nav-list .list-box .list-item i {
  font-size: 0.426667rem;
  font-weight: 400;
  color: #999999;
}

.top-menu .nav-list .list-box .list-item.active {
  color: #f5333f;
}